.dashboard-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #FFF;

	.dashboard__header {
		position: fixed;
		top: 15px;
		width: 100%;
		height: 58px;
		background-color: #FFF;
		border-bottom: 1px solid #e5e5e5;
		text-align: center;
		display: flex;
		align-items: center;
		.dashboard__header__title {
			flex: 0 100%;
			padding-top: 4px;
			@include font-header-title();
		}

		.dashboard__header__logout-btn,
		.dashboard__header__csv-btn {
			font-family: $ff-regular;
		  color: #232323;
		  font-size: 1rem;
		  line-height: 1;
		  letter-spacing: 1px;
			position: absolute;
			right: 0;
			padding: 20px;
			padding-top: 22px;
			padding-bottom: 18px;
			padding-right: 18px;
			cursor: pointer;
		}

		.dashboard__header__csv-btn {
			right: auto;
			left: 0;
			padding-top: 18px;
    	padding-bottom: 18px;
			a {
				color: $c-black;
				text-decoration: none;
			}

			svg {
				path {
					fill: $c-accent;
				}
			}
		}
	}

	.dashboard__scroll-container {
		position: relative;
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		height: calc(100% - 73px);
		top: 73px;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	.dashboard__total-check-ins {
		position: relative;
		margin-top: 0;
		width: 100%;
		text-align: center;
		padding-top: 40px;
		padding-bottom: 50px;
		.dashboard__stats-value {
			font-size: 6rem;
		}
		.dashboard__stats-label {
			font-size: 1.2rem;
			font-family: $ff-light;
		}
	}

	.dashboard__event-info {
		position: relative;
		padding: 20px;
		padding-top: 0;
		padding-bottom: 100px;
		.dashboard__event-info__item {
			display: flex;
			align-items: center;
			padding-top: 17px;
			padding-bottom: 14px;
			border-bottom: 1px solid #e5e5e5;
			&:first-child {
				border-top: 1px solid #232323;
				padding-top: 27px;
			}
			> div {
				flex: 1 auto;
				text-transform: uppercase;
			}
		}

		.dashboard__event-info-label {
			@include dashboard-event-info-label();
			padding-right: 30px;
		}

		.dashboard__event-info-value {
			padding-top: 1px;
			@include dashboard-event-info-value();
		}
	}

	.dashboard__stats {
		position: relative;
		width: calc(100% - 40px);
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		margin-left: 20px;
		margin-right: 20px;
		padding-top: 20px;
		border-top: 2px solid #232323;
		> div {
			flex: 0 33.333333%;
			text-align: center;
			text-transform: uppercase;
			span {
				display: inline-block;
				text-align: center;
				width: 100%;
			}
		}

		.dashboard__detailed-stats {
			flex: 0 100%;
			padding: 0;
			padding-top: 20px;
			padding-bottom: 11px;
			.dashboard__detailed-stats__item {
				display: flex;
				align-items: center;
				padding-top: 17px;
				padding-bottom: 14px;
				border-bottom: 1px solid #e5e5e5;
				&:first-child {
					border-top: 1px solid #e5e5e5;
				}
				&:last-child {
					border-bottom: none;
				}
				> div {
					flex: 1 auto;
					text-transform: uppercase;
					line-height: 1;
				}
			}

			.dashboard__detailed-stats-label {
				@include dashboard-event-info-label();
				padding-right: 30px;
				text-align: left;
			}

			.dashboard__detailed-stats-value {
				padding-top: 1px;
				@include dashboard-event-info-value();
			}
		}
	}

	.dashboard__stats__tickets {
		.dashboard__stats-label {
			//font-family: $ff-bold;
			//background-color: $c-ticket;
			//color: #FFF;
			//color: $c-ticket;
			//padding: 3px;
	    padding-left: 3px;
	    //padding-right: 8px;
			padding-bottom: 0;
	    padding-top: 2px;
	    border-radius: 3px;
		}

		.dashboard__stats-value {
			color: $c-ticket;
		}
	}

	.dashboard__stats__rsvps {
		.dashboard__stats-label {
			//font-family: $ff-bold;
			//background-color: $c-rsvp;
			//color: #FFF;
			//color: $c-rsvp;
			//padding: 3px;
	    //padding-left: 9px;
	    //padding-right: 8px;
			padding-left: 3px;
	    padding-top: 2px;
			padding-bottom: 0;
	    border-radius: 3px;
		}

		.dashboard__stats-value {
			color: $c-rsvp;
		}
	}

	.dashboard__stats__guests {
		.dashboard__stats-label {
			//font-family: $ff-bold;
			//background-color: $c-guest;
			//color: #FFF;
			//color: $c-guest;
			//padding: 3px;
	    //padding-left: 9px;
	    //padding-right: 8px;
			padding-left: 3px;
	    padding-top: 2px;
			padding-bottom: 0;
	    border-radius: 3px;
		}

		.dashboard__stats-value {
			color: $c-guest;
		}
	}

	.dashboard__stats-value {
		@include dashboard-stats-value();
	}

	.dashboard__stats-label {
		@include dashboard-stats-label();
	}
}
