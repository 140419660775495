@font-face {
  font-family: 'Miso';
  src: url('assets/font/2D9D62_0_0.eot');
  src: url('assets/font/2D9D62_0_0.eot?#iefix') format('embedded-opentype'),url('assets/font/2D9D62_0_0.woff2') format('woff2'),url('assets/font/2D9D62_0_0.woff') format('woff'), url('assets/font/2D9D62_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Miso-Bold';
  src: url('assets/font/2D9D62_1_0.eot');
  src: url('assets/font/2D9D62_1_0.eot?#iefix') format('embedded-opentype'), url('assets/font/2D9D62_1_0.woff2') format('woff2'),url('assets/font/2D9D62_1_0.woff') format('woff'),url('assets/font/2D9D62_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Miso-Light';
  src: url('assets/font/2D9D62_2_0.eot');
  src: url('assets/font/2D9D62_2_0.eot?#iefix') format('embedded-opentype'),url('assets/font/2D9D62_2_0.woff2') format('woff2'),url('assets/font/2D9D62_2_0.woff') format('woff'),url('assets/font/2D9D62_2_0.ttf') format('truetype');
}

.miso {
  font-family: 'Miso', Helvetica, sans-serif;
}

.miso-bold {
  font-family: 'Miso-Bold', Helvetica, sans-serif;
}

.miso-light {
  font-family: 'Miso-Light', Helvetica, sans-serif;
}

// font families
$ff-light: 'Miso-Light', 'Helvetica', 'Arial', sans-serif;
$ff-regular: 'Miso', 'Helvetica', 'Arial', sans-serif;
$ff-bold: 'Miso-Bold', 'Helvetica', 'Arial', sans-serif;

// font weights
$fw-regular: 400;
$fw-bold: 700;

// font sizes
$fs-base: 16px;

// Font Resets
h1, h2, h3, h4, h5, h6,
p {
  margin: 0;
}

// Font Definitions

/*
 * Global
*/

@mixin font-button() {
  font-family: $ff-regular;
  font-size: 1.5625rem;
  line-height: 1;
  letter-spacing: 2px;
}

@mixin font-login {
  font-family: $ff-light;
  font-size: 1.5625rem;
  line-height: 1;
  letter-spacing: 1px;
}

@mixin font-input {
  font-family: $ff-regular;
  font-size: 1rem;
  line-height: 1;
}

@mixin font-tabs {
  font-family: $ff-regular;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 1px;
}

@mixin font-list-item {
  font-family: $ff-light;
  font-size: 1.3rem;
  line-height: 1rem;
  padding-left: 10px;
  letter-spacing: 0.5px;
}

@mixin font-ticket-overlay-caption {
  font-family: $ff-regular;
  font-size: 1.5rem;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}

@mixin font-ticket-overlay-msg {
  font-family: $ff-light;
  font-size: 1.125rem;
  letter-spacing: 1px;
  line-height: 1;
  text-align: center;
}

@mixin font-attendee-tier-stamp {
  font-family: $ff-regular;
  color: #FFF;
  font-size: 1rem;
  line-height: 1;
}

@mixin font-header-title {
  font-family: $ff-regular;
  color: #232323;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 1px;
}

@mixin dashboard-stats-value {
  font-family: $ff-light;
  color: #232323;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 1px;
}

@mixin dashboard-stats-label {
  font-family: $ff-light;
  color: #232323;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 3px;
}


@mixin dashboard-event-info-value {
  font-family: $ff-regular;
  color: #232323;
  font-size: 1.2rem;
  line-height: 1;
  letter-spacing: 2px;
  text-align: right;
}

@mixin dashboard-event-info-label {
  font-family: $ff-regular;
  color: rgba(0,0,0,0.3);
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 2px;
}

@mixin font-no-results {
  font-family: $ff-regular;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 2px;
}
