.login-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #000;
}

.login__bg {
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
	opacity: 0.3;
}

.login__logo {
	position: absolute;
	width: 100%;
	top: 0;
	text-align: center;
}

.login__input-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 70%;

	&.invalid {
		input {
			animation: shake .5s linear;
		}
	}

	input {
		position: relative;
		margin-bottom: 0;
		padding: 10px;
		padding-top: 15px;
		padding-left: 0;
		padding-right: 0;
		@include font-login();
		text-align: center;
		border-radius: 0;
	}

	.login__button {
		position: relative;
		margin-top: 10px;
		background-color: $c-green;
		width: 100%;
		padding: 10px;
		padding-top: 15px;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
		color: #FFF;
		cursor: pointer;
		@include font-button();
		&.disabled {
			background-color: #999;
			pointer-events: none;
			color: rgba(255, 255, 255, 0.2)
		}
	}
}
