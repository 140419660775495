// import mixins
@import 'mixins/mixins';

// import variables
@import 'vars/fonts';
@import 'vars/z-indexes';
@import 'vars/colors';
@import 'vars/transitions';
@import 'vars/sizes';
@import 'vars/media-queries';

@import 'ui-resets';


html,
body {
	width: 100%;
	height: 100%;
	font-family: $ff-regular;
	overflow: hidden;
	-webkit-tap-highlight-color: transparent;
}

html {
	font-family: $ff-regular;
	line-height: 1;
}

body {
	font-size: $fs-base;
	line-height: 1.15;
	-webkit-font-smoothing: antialiased;
}

div {
	line-height: 0;
}

img {
	max-width: 100%;
	width: auto;
}

* {
	outline: none;
	&:focus {
		outline:none;
	}
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #b7b7bf;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #b7b7bf;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #b7b7bf;
}
:-moz-placeholder { /* Firefox 18- */
  color: #b7b7bf;
}

.btn-standard {
	@include font-button();
	border: 2px solid $c-white;

	&:hover {
		background-color: $c-white;
		color: $c-black;
	}
}

#app {
	position: relative;
	width: 100%;
	height: 100%;

	> div {
		position: relative;
		width: 100%;
		height: 100%;
	}
}

#content {
	position: relative;
	width: 100%;
	height: 100%;
}




.standard-transition--fade-enter {
  opacity: 0;
}

.standard-transition--fade-enter.standard-transition--fade-enter-active {
  opacity: 1;
  transition: opacity 0.4s ease;

}

.standard-transition--fade-leave {
  opacity: 1;
}

.standard-transition--fade-leave.standard-transition--fade-leave-active {
  opacity: 0;
  transition: opacity 0.4s ease,;
}
