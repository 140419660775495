// transitions
$t-easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$t-easeOut: ease-out;

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}


@keyframes shake {
   8%, 41% {
       -webkit-transform: translateX(-10px);
   }
   25%, 58% {
       -webkit-transform: translateX(10px);
   }
   75% {
       -webkit-transform: translateX(-5px);
   }
   92% {
       -webkit-transform: translateX(5px);
   }
   0%, 100% {
       -webkit-transform: translateX(0);
   }
}
