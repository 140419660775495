// colors
$c-white: #FFF;
$c-black: #000;
$c-off-black: #222;

$c-grey-regular: #CCC;
$c-grey-dark: #232323;
$c-accent: #ff5335;
$c-green: #79e87e;
$c-ticket: #55DDe0;
$c-rsvp: #F6AE2D;
$c-guest: #FC6471;

$c-ticket-success: #52cf2b;
$c-ticket-error: #ff2a00;
