.SVGInline {
  line-height: 0;
}

.btn {
  position: relative;
  display: block;
  width: 100%;
  background-color: $c-black;
  cursor: pointer;
  @include font-button();
}

.loader-overlay {
  position: fixed;
  z-index: 9999999;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: opacity 0.5s ease;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    border-radius: 50%;
    border: 1px solid #FFF;
    border-top-color: #232323;
    animation: spinner .6s linear infinite;
  }
}

.spinner {
  position: relative;
  z-index: 9999999;
  display: inline-block;
  width: 26px;
  height: 26px;
  opacity: 1;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid #FFF;
    border-top-color: #232323;
    animation: spinner .6s linear infinite;
  }
}
