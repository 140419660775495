.menu-bar {
  position: fixed;
  width: 100%;
  display: flex;
  bottom: 0;
  height: 50px;
  background-color: $c-grey-dark;

  a {
    display: flex;
    flex: 1 33.3333333%;
    align-items: center;
    justify-content: center;
  }

  .menu-item {
    opacity: 0.5;
    cursor: pointer;
    &.active {
      opacity: 1;
    }
    &.qr-code-maybe {
      opacity: 1;
      svg {
        path,
        polygon {
          fill: $c-accent;
        }
      }
    }
  }
}
