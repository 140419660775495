.pt-tabs {
	z-index: 1;
	position: relative;
	width: 70%;
}

.pt-tab {
	flex: 0 100%;
	text-align: center;
	margin-right: 0 !important;
	color: #999999;
	font-size: 12px;
  padding-bottom: 10px;
	padding-left: 5px;
  @include font-tabs();

	&:first-child {
		flex: 0 100%;
	}
	&:last-child {
		flex: 0 100%;
	}
}

.pt-toast-container {
	padding-top: 100px;
}

.pt-toast-message {
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	margin-top: 2px;
}

#pt-tab-title_filterTabs_tickets {
	flex: 0 100%;
}

#pt-tab-title_filterTabs_rsvp {
	flex: 0 100%;
}

#pt-tab-title_filterTabs_guestlist {
	flex: 0 100%;
}

.pt-tab[aria-selected="true"] {
	box-shadow: inset 0 -1px 0 $c-accent;
}

.pt-input[type="search"],
.pt-input.pt-round,
.pt-input-group .pt-input:not(:first-child) {
	border-radius: 0;
	padding-left: 26px;
	height: auto;
	// padding-top: 6px;
	// padding-bottom: 2px;
	padding-top: 2px;
	box-shadow: none;
	border: none;
	background-color: transparent;
	@include font-input();
	line-height: 1.8;
	&:focus {
		border: none;
		outline: none;
		box-shadow: none;
	}
}

.pt-input-group .pt-icon {
	font-size: 11px;
	line-height: 31px;
  color: #a5a7aa;
}

.pt-tab[aria-selected="true"] {
	color: $c-accent !important;
}

.pt-tab:not([aria-disabled="true"]):hover {
	color: #999999;
	&.active {
		color: $c-accent;
	}
}

.pt-tab[aria-selected="true"] {
  border-radius: 0;
  box-shadow: inset 0 -1px 0 $c-accent;
}
