* {
	outline: none;
}

button {
	border: none;
	border-radius: 0;
	padding: 0;
	background-color: transparent;
	cursor: pointer;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

blockquote {
	margin: 0;
}

input[type="submit"] {
	appearance: none;
	border: none;
}

a {
	text-decoration: none;
	color: $c-white;
}

div.clearfix {
	&:after {
		clear: both;
		display: block;
		content: "";
	}
}


input[type="text"] {
	position: relative;
	display: block;
	width: 100%;
	border: none;
	border-bottom: 1px solid $c-black;
	padding: 5px;
	margin-bottom: 15px;
	color: $c-off-black;
	box-sizing: border-box;

	&::placeholder {
		color: $c-grey-regular;
	}
}

input[type="submit"] {
	margin-top: 45px;
}

select {
	color: $c-off-black;
	background: transparent;
	width: 100%;
	border: 0;
	border-radius: 0;
	height: 34px;
	appearance: none;
	line-height: 1.15;
	border-bottom: 1px solid $c-black;
	padding: 5px;
	background-size: 15px 8px;
	background-repeat: no-repeat;
	background-position: calc(100% - 5px) center;

	&.no-selection {
		color: $c-grey-regular;
	}
}
